import React from "react";
import { Link } from "react-router-dom";


import smileplusMockup from '../../assets/img-web/smileplus-mockup-login.png'
import FullSizeRendersmile0023 from '../../assets/img-web/FullSizeRendersmile0023-1-2048x1003.jpg'
import smileplusMockupPromotion from '../../assets/img-web/smileplus.png'

import logoToyotabara from '../../assets/img/logo/logo-toyotabara.png'





const Body = () => {

    return (
        <>
            <section id="smileplus" className="g-theme-bg-gray-light-v1- g-bg-black- g-bg-gray-dark-v2 g-pt-90 g-color-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 ml-lg-auto d-flex text-center text-md-left g-font-size-16">
                            <div className="align-self-center">
                                <h2 className="text-uppercase g-line-height-1_3 g-font-size-36 g-mb-20 g-mb-30--lg ">SMILEPLUS คืออะไร</h2>
                                <p className="g-mb-20 g-mb-35--lg g-color-white">
                                    <span className="text-underline ">สไมล์พลัส</span> เว็ปแอปพลิเคชั่น บริการใหม่จากพาราวินเซอร์ มาพร้อมให้บริการครบวงจรด้านรถยนต์รวมถึงสินค้าและบริการต่างๆในเครือพาราวินเซอร์ ซึ่งเป็นตัวแทนจำหน่ายรถยนต์โตโยต้าในประเทศไทย ซึ่งจะรวบรวมโปรโมชั่นและส่วนลดมากมายไว้ครบจบในที่เดียว
                                </p>

                                <div className="text-center">
                                <Link 
                                className="btn  w-100 g-px-50 g-py-20 btn-onesmile g-color-white g-font-size-30" 
                                to="https://lin.ee/iWZrXlS" 
                                > 
                                ใช้งานฟรี สมัครเลย!
                                </Link>
                                <a className="d-none" href="https://lin.ee/iWZrXlS"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0" /></a>
                            </div>
                                <Link to="/signup" className="d-none btn btn-lg btn-onesmile w-80 g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md g-color-white">
                                    <span className="float-right g-font-size-13 ">
                                        รับสิทธิ์พิเศษ
                                        <span className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">สมัครฟรี ไม่มีค่าใช้จ่าย</span>
                                    </span>
                                    <i className="fa fa-user float-left g-font-size-26 g-mr-15"></i>
                                </Link>
                                <Link to="/login" className="d-none btn btn-lg btn-onesmile g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md g-color-white">
                                    <span className="float-right g-font-size-13">
                                        เข้าสู่ระบบ
                                        <span className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">ใช้ฟรี ไม่มีค่าใช้จ่าย</span>
                                    </span>
                                    <i className="fa fa-sign-in float-left g-font-size-26 g-mr-15"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 g-overflow-hidden-">
                            <img className="img-fluid slideInUp u-in-viewport" src={smileplusMockup} data-animation="slideInUp" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="g-bg-black g-pt-100 g-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 g-mb-30">
                            <div className="g-mb-20">
                                <span className="d-block g-color-white g-font-weight-700 g-font-size-13 text-uppercase"> </span>
                                <h2 className="h1 g-color-white g-font-weight-700 text-uppercase">ทำไมต้องเป็นสมาชิก SMILEPLUS</h2>
                            </div>
                            <p className="lead g-font-size-20 g-color-white">เพลิดเพลินกับสิทธิประโยนชน์และบริการมากมาย พร้อมส่วนลดสุดพิเศษและกิจกรรม ครอบคลุมทุกไลฟ์สไตร์การใช้รถยนต์ที่คัดสรรมาให้เฉพาะสมาชิก SMILEPLUS</p>
                            <p className="lead"> </p>
                        </div>
                        <div className="col-md-5 g-mb-30">
                            <div className="text-center">
                                <Link 
                                className="btn g-px-50 g-py-20 btn-onesmile g-color-white g-font-size-30" 
                                to="https://lin.ee/iWZrXlS" 
                                > 
                                ใช้งานฟรี สมัครเลย!
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall under-760"
                style={{ backgroundImage: 'url(assets/img/pattern1.png)' }} >


                <div className="container u-bg-overlay__inner g-color-white g-py-100">
                    <header className="text-center g-width-80x--md mx-auto g-mb-70">
                        <div className="u-heading-v6-2 text-center text-uppercase g-mb-20">
                            <h6 className="g-font-size-12 g-font-weight-600">Our Solutions</h6>
                            <h2 className="h3 u-heading-v6__title g-brd-primary g-font-weight-600">Our Offers</h2>
                        </div>
                        <p className="lead">SMILE HAPPINESS <span className="g-color-primary"></span>
                            บริการครบวงจร จากพาราวินเซอร์ มาพร้อมโปรโมชั่นสุดพิเศษในที่เดียว
                        </p>
                    </header>

                     <div className="row">
                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-purple g-height-140 g-width-140" data-circles-value="53" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-1">
                                        <div className="circles-wrp" ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140"  >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.70182450295923 76.40775389388446 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text" >
                                                <img width="100%" src={logoToyotabara} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Showroom Toyota</h4>
                                    <p className="mb-0">ซื้อรถยนต์ใหม่ราคาสุดพิเศษ</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-pink g-height-140 g-width-140" data-circles-value="96" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-2">
                                        <div className="circles-wrp" ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140"  >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 78.72552213475265 138.44607558856785 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text">
                                                <img width="100%" src="assets/img/logo/logo-toyotabara.png" className="img-fluid" />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">General Service Body & Paint</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">ส่วนลดพิเศษเมื่อเข้าศูนย์บริการ</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-cyan g-height-140 g-width-140" data-circles-value="8" data-circles-max-value="15" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-additional-text="k" data-circles-scroll-animate="true" id="hs-pie-3">
                                        <div className="circles-wrp"  ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140"  >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 55.73751360860836 137.50986210869763 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text">
                                                <img width="100%" src="assets/img/logo/logo-baracoatings.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Paints and Coatings</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">ศูนย์ตัวถังและสีกับสิทธิพิเศษเฉพาะคุณ</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-primary g-height-140 g-width-140" data-circles-value="48" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-4">
                                        <div className="circles-wrp"  ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140"  >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.85873622791087 65.58701403865867 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text">
                                                <img width="100%" src="assets/img/logo/logo-toyotasure.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Toyota Sure</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">รถใช้แล้วสภาพดีคัดสรรมาเพื่อคุณ</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-primary g-height-140 g-width-140" data-circles-value="48" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-4">
                                        <div className="circles-wrp"  ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.85873622791087 65.58701403865867 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text">
                                                <img width="100%" src="assets/img/logo/logo-aab.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Insurance Brokerage</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">ประกันภัยรถยนต์ครบวงจร</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-primary g-height-140 g-width-140" data-circles-value="48" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-4">
                                        <div className="circles-wrp" ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.85873622791087 65.58701403865867 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text">
                                                <img width="100%" src="assets/img/logo/logo-kratosTrading.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Car Gadgets and Accessories</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">ศูนย์รวมอุปกรณ์ตกแต่งรถยนต์</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-primary g-height-140 g-width-140" data-circles-value="48" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-4">
                                        <div className="circles-wrp" ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.85873622791087 65.58701403865867 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text" >
                                                <img width="100%" src="assets/img/logo/logo-carrent.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Car Rental & Leasing</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">เช่ารถยนต์ราคาสุดพิเศษ</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 g-mb-60">
                            <div className="media">
                                <div className="g-height-140 g-width-140 g-mr-30">
                                    <div className="js-pie-rtl u-chart-pie-v1 g-brd-primary g-height-140 g-width-140" data-circles-value="48" data-circles-max-value="200" data-circles-bg-color="#111" data-circles-fg-color="#FE554a" data-circles-radius="70" data-circles-stroke-width="2" data-circles-font-size="35" data-circles-duration="2000" data-circles-scroll-animate="true" id="hs-pie-4">
                                        <div className="circles-wrp"  ><svg xmlns="http://www.w3.org/2000/svg" width="140" height="140"  >
                                            <path fill="transparent" stroke="#111" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 1 1 69.90416038427306 1.0000665596838587 Z" className="circles-maxValueStroke"></path>
                                            <path fill="transparent" stroke="#FE554a" stroke-width="2" d="M 69.98594654894504 1.0000014311557095 A 69 69 0 0 1 138.85873622791087 65.58701403865867 " className="circles-valueStroke"></path>
                                        </svg>
                                            <div className="circles-text" >
                                                <img width="100%" src="assets/img/logo/logo-kratosautowork.png" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-body align-self-center g-line-height-2">
                                    <h4 className="h5 g-font-weight-400 g-letter-spacing-1 g-mb-15">Car Detailing</h4>
                                    <p className="mb-0 g-color-white-opacity-0_8">โปรโมชั่นสุดพิศษสำหรับสมาชิกโดยเฉพาะ</p>
                                </div>
                            </div>
                        </div>
                    </div>  


                </div>
            </section> */}

            <section className="g-bg-white"  >
                <div className="container">


                    <div className="row">

                        <header className="text-center g-width-80x--md mx-auto g-mb-70 g-pt-50">

                            <p className="lead">SMILE HAPPINESS <span className="g-color-primary"></span>
                                บริการครบวงจร จากพาราวินเซอร์ มาพร้อมโปรโมชั่นสุดพิเศษในที่เดียว
                            </p>
                        </header>


                        <div className="col-lg-12 g-py-20">
                            <img className="img-fluid slideInUp u-in-viewport" src={FullSizeRendersmile0023} />
                        </div>

                    </div>
                </div>
            </section>

            <section id="benefit" className="g-theme-bg-gray-light-v1 g-py-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 text-center g-overflow-hidden- g-mb-50 g-mb-0--md">
                            <img className="img-fluid slideInLeft u-in-viewport" src={smileplusMockupPromotion} data-animation="slideInLeft" />
                        </div>

                        <div className="col-md-7 d-flex text-center text-md-left">
                            <div className="align-self-center">
                                <div className="text-uppercase g-mb-20">
                                    <h4 className="g-font-weight-700 g-font-size-11 g-mb-15">
                                        <h2 className="g-line-height-1_3 g-font-size-36 mb-0">สิทธิประโยชน์จากสมาชิก <strong>SMILEPLUS</strong> </h2>
                                    </h4>
                                </div>

                                <p className="g-font-size-16 g-mb-65">รวมเป็นครอบครัว SMILEPLUS เพื่อรับสิทธิประโยชน์มากมาย</p>

                                <div className="media d-block d-md-flex text-center text-md-left g-mb-40">
                                    <div className="d-md-flex align-self-center g-mb-30 g-mb-0--md g-mr-30--md">
                                        <span className="u-icon-v2 u-icon-size--lg g-font-size-26 g-color-primary g-rounded-50x"><i className="fa fa-gift"></i></span>
                                    </div>

                                    <div className="media-body align-self-center">
                                        <div className="h6 text-uppercase g-font-weight-700 g-color-black g-mb-15 g-font-size-30">รับส่วนลดพิเศษ</div>
                                        <p className="mb-0 g-font-size-16">รับส่วนลดพิเศษจากกลุ่มธุรกิจพาราเวอร์และพันธมิตรที่ร่วมรายการ</p>
                                    </div>
                                </div>

                                <div className="media d-block d-md-flex text-center text-md-left">
                                    <div className="d-md-flex align-self-center g-mb-30 g-mb-0--md g-mr-30--md">
                                        <span className="u-icon-v2 u-icon-size--lg g-font-size-26 g-color-primary g-rounded-50x"><i className="fa fa-magic"></i></span>
                                    </div>

                                    <div className="media-body align-self-center">
                                        <div className="h6 text-uppercase g-font-weight-700 g-color-black g-mb-15 g-font-size-30">กิจกรรม</div>

                                        <p className="mb-0 g-font-size-16">พบกับกิจกรรมและของรางวัลพิเศษสำหรับสมาชิกคนสำคัญคัดมาพิเศษเพื่อคุณ</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )

}

export default Body;