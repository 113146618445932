import React from "react";
import { Link } from "react-router-dom";

const Header = () => {

    return (

        <>

            <header id="js-header" className="u-header u-header--static u-header--show-hide u-header--change-appearance" data-header-fix-moment="0" data-header-fix-effect="slide">
                <div className="u-header__section  g-brd-bottom g-brd-gray-light-v4 g-bg-white g-transition-0_3">
                    <div className="container">
                        <div className="row justify-content-center align-items-center g-mx-0--lg   -d-sm-block  -d-md-none -d-sm-none">
                            <div className="g-hidden-sm-down- g-color-black-opacity-0_6 g-font-weight-400 g-pl-10 g-pl-0--sm g-py-14">
                                <a href="#">
                                    <a href="#"> <img width="100px" src="/logo/logo.png" /> </a>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10 u-shadow-v18">
                    <nav className="js-mega-menu navbar navbar-expand-lg g-font-weight-600">
                        <div className="container   justify-content-center  ">
                            <button className="navbar-toggler navbar-toggler-center- btn g-line-height-1 g-brd-none g-pa-0 g-pos-abss g-top-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                                <span className="hamburger hamburger--slider g-pr-0">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </span>
                            </button>
                            <a className="navbar-brand d-none=" href="#"> </a>

                            <div className="collapse navbar-collapse align-items-center flex-sm-row" id="navBar" data-mobile-scroll-hide="true">
                                <div className="collapse navbar-collapse align-items-center flex-sm-row g-pt-15 g-pt-0--lg  g-font-weight-600 g-font-size-16" id="navBar">
                                    <ul id="js-scroll-nav" className="navbar-nav mx-auto">
                                        <li className="nav-item g-mx-10--lg g-mx-15--xl">
                                            <a className="nav-link text-uppercase -g-color-primary--hover g-pl-5 g-pr-0 g-py-20" href="#smileplus">SMILEPLUS </a>
                                        </li>
                                        <li className="nav-item g-mx-10--lg g-mx-15--xl">
                                            <a className="nav-link text-uppercase -g-color-primary--hover g-pl-5 g-pr-0 g-py-20" href="#benefit"> สิทธิประโยชน์</a>
                                        </li>
                                        <li className="nav-item g-mx-10--lg g-mx-15--xl">
                                            <a className="nav-link text-uppercase -g-color-primary--hover g-pl-5 g-pr-0 g-py-20" href="#contactus">ติดต่อเรา</a>
                                        </li>
                                        <li className="nav-item g-mx-10--lg g-mx-15--xl">
                                            <Link
                                                 to="https://lin.ee/iWZrXlS" 
                                                className="nav-link text-uppercase -g-color-primary--hover g-pl-5 g-pr-0 g-py-20">สมัครสมาชิก</Link>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </nav>
                </div>
            </header>

        </>
    )

}

export default Header;